(function () {
  'use strict';

  angular
    .module('neo.home.statistics.entities')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.statistics.entities', {
        url: '/entities',
        templateUrl: 'home/statistics/entities/entities.tpl.html',
        controller: 'StatsEntitiesCtrl',
        controllerAs: 'vm',
        resolve: {
          entities: function (Entitats) {
            return Entitats.query().$promise;
          },
          programmes: function (Programmes) {
            return Programmes.query().$promise;
          }
        }
      });
  }
}());
